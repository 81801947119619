<template>
  <b-form-group
    class="resource-text sortable-field-container"
    :tabindex="schemaTabIndex(field.id)"
    :disabled="isDisabled"
  >
    <validation-observer ref="observer" v-slot="{ invalid }" />

    <schema-form-field-label
      v-if="showFieldLabel"
      :label="title"
      :help-text="field.field_help_text"
    />

    <validation-provider
      v-slot="validationContext"
      :rules="validationRules(field)"
      :name="field.name"
    >
      <b-form-select
        v-if="field.meta.type === 'select'"
        v-model="value"
        :state="validationState(validationContext)"
        class="resource-select"
        :options="field.meta.options"
        @input="valueUpdated"
        @keydown.enter.prevent="handleEnterPressed"
        @focus="setCurrentFieldById(field.id)"
      />
      <b-form-input
        v-else-if="field.meta.type === 'number'"
        v-model="value"
        :placeholder="placeholderText"
        :state="validationState(validationContext)"
        class="resource-number"
        type="number"
        :min="field.meta.min_value"
        :max="field.meta.max_value"
        @input="valueUpdated"
        @keydown.enter.prevent="handleEnterPressed"
        @focus="setCurrentFieldById(field.id)"
      />
      <b-form-input
        v-else-if="field.meta.type === 'phone'"
        v-model="value"
        v-mask="'###############'"
        type="text"
        :state="validationState(validationContext)"
        placeholder="Phone Number"
        @input="valueUpdated"
        @keydown.enter.prevent="handleEnterPressed"
        @focus="setCurrentFieldById(field.id)"
      />
      <b-form-input
        v-else-if="field.meta.type === 'date'"
        :ref="field.id"
        v-model="value"
        :state="validationState(validationContext)"
        type="date"
        @input="valueUpdated"
        @keydown.enter.prevent="handleEnterPressed"
        @focus="setCurrentFieldById(field.id)"
      />
      <b-form-input
        v-else-if="field.meta.type === 'ssn'"
        v-model="value"
        v-mask="'###-##-####'"
        :state="validationState(validationContext)"
        placeholder="Social Security Number"
        type="text"
        @input="valueUpdated"
        @keydown.enter.prevent="handleEnterPressed"
        @focus="setCurrentFieldById(field.id)"
      />
      <b-form-input
        v-else-if="field.meta.type === 'signature'"
        v-model="value"
        :state="validationState(validationContext)"
        class="resource-signature"
        @input="valueUpdated"
        @keydown.enter.prevent="handleEnterPressed"
        @focus="setCurrentFieldById(field.id)"
      />
      <b-form-checkbox
        v-else-if="field.meta.type === 'checkbox'"
        v-model="value"
        class="resource-checkbox tab-order-schema-checkbox"
        value="true"
        unchecked-value="false"
        @input="valueUpdated"
        @keydown.enter.prevent="handleEnterPressed"
        @focus="setCurrentFieldById(field.id)"
      />
      <b-form-textarea
        v-else-if="field.meta.type === 'multiline_text'"
        v-model="value"
        id="text-area-input"
        :placeholder="placeholderText"
        :state="validationState(validationContext)"
        :max-length="field.meta.character_limit"
        no-resize
        class="text-area-input"
        @input="valueUpdated"
        @keydown.enter.prevent="handleEnterPressed"
        @focus="setCurrentFieldById(field.id)"
      />
      <b-form-input
        v-else
        v-model="value"
        :placeholder="placeholderText"
        :state="validationState(validationContext)"
        :max-length="field.meta.character_limit"
        @input="valueUpdated"
        @keydown.enter.prevent="handleEnterPressed"
        @focus="setCurrentFieldById(field.id)"
      />

      <b-form-text v-if="isDisabled">
        This field can't be changed at this time.
      </b-form-text>

      <p v-if="errors(field.name)" class="invalid">
        {{ errors(field.name) }}
      </p>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  inject: ['$validator'],
  name: 'PdfSchemaFormField',
  components: {
    SchemaFormFieldLabel: () => import('@/components/SchemaForm/fields/SchemaFormFieldLabel'),
  },
  props: {
    field: Object,
    fieldValue: [Boolean, String],
    useHint: {
      type: Boolean,
      default: false,
    },
    useSuggestion: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showFieldLabel: {
      type: Boolean,
      default: true,
    },
    placeholderText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      value: null,
    }
  },
  computed: {
    ...mapGetters('stagelineSchemaForm', [
      'pdfGroupField',
      'validationRules',
      'validationState',
      'resourceFields',
      'schemaTabIndex',
    ]),
    title() {
      return this.pdfGroupField ? this.field.original_field_label : this.field.title
    },
    isDisabled() {
      return this.useHint || this.disabled
    },
    observer() {
      return this.$refs.observer?.$_veeObserver
    },
  },
  watch: {
    fieldValue() {
      this.initValue()
    },
    useSuggestion(newValue, _oldValue) {
      this.value = newValue ? this.field.meta.suggestion.value : ''
      this.valueUpdated()
    },
  },
  created() {
    this.$validator = this.$parent.$validator
  },
  mounted() {
    this.initValue()
  },
  methods: {
    ...mapActions('stagelineSchemaForm', [
      'setFormValue',
      'setCurrentFieldById',
    ]),
    initValue() {
      // TODO Hacky, probably a better way to do this when we have time
        this.value = this.fieldValue === true ? '' : this.fieldValue
    },
    valueUpdated() {
      this.setFormValue({
        key: this.field.id,
        value: this.useSuggestion ? this.field.meta.suggestion.value : this.value,
      })
    },
    errors(fieldName) {
      return this.observer?.errors && this.observer.errors[fieldName] ?
        this.observer.errors[fieldName].filter(error => !error.includes('required'))[0] :
        null
    },
    handleEnterPressed(e) {
      this.$emit('enterPressed', e.shiftKey ? 'prev' : 'next')
    },
  },
}
</script>
<style lang="scss" scoped>
  .invalid {
    font-size: 80%;
    color: #dc3545;
    margin-bottom: 0;
  }
  .text-area-input {
    height: 120px !important;
  }
</style>
