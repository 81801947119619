var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form-group",
    {
      staticClass: "resource-text sortable-field-container",
      attrs: {
        tabindex: _vm.schemaTabIndex(_vm.field.id),
        disabled: _vm.isDisabled,
      },
    },
    [
      _c("validation-observer", {
        ref: "observer",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ invalid }) {
              return undefined
            },
          },
        ]),
      }),
      _vm.showFieldLabel
        ? _c("schema-form-field-label", {
            attrs: { label: _vm.title, "help-text": _vm.field.field_help_text },
          })
        : _vm._e(),
      _c("validation-provider", {
        attrs: { rules: _vm.validationRules(_vm.field), name: _vm.field.name },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (validationContext) {
              return [
                _vm.field.meta.type === "select"
                  ? _c("b-form-select", {
                      staticClass: "resource-select",
                      attrs: {
                        state: _vm.validationState(validationContext),
                        options: _vm.field.meta.options,
                      },
                      on: {
                        input: _vm.valueUpdated,
                        keydown: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          $event.preventDefault()
                          return _vm.handleEnterPressed.apply(null, arguments)
                        },
                        focus: function ($event) {
                          return _vm.setCurrentFieldById(_vm.field.id)
                        },
                      },
                      model: {
                        value: _vm.value,
                        callback: function ($$v) {
                          _vm.value = $$v
                        },
                        expression: "value",
                      },
                    })
                  : _vm.field.meta.type === "number"
                  ? _c("b-form-input", {
                      staticClass: "resource-number",
                      attrs: {
                        placeholder: _vm.placeholderText,
                        state: _vm.validationState(validationContext),
                        type: "number",
                        min: _vm.field.meta.min_value,
                        max: _vm.field.meta.max_value,
                      },
                      on: {
                        input: _vm.valueUpdated,
                        keydown: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          $event.preventDefault()
                          return _vm.handleEnterPressed.apply(null, arguments)
                        },
                        focus: function ($event) {
                          return _vm.setCurrentFieldById(_vm.field.id)
                        },
                      },
                      model: {
                        value: _vm.value,
                        callback: function ($$v) {
                          _vm.value = $$v
                        },
                        expression: "value",
                      },
                    })
                  : _vm.field.meta.type === "phone"
                  ? _c("b-form-input", {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: "###############",
                          expression: "'###############'",
                        },
                      ],
                      attrs: {
                        type: "text",
                        state: _vm.validationState(validationContext),
                        placeholder: "Phone Number",
                      },
                      on: {
                        input: _vm.valueUpdated,
                        keydown: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          $event.preventDefault()
                          return _vm.handleEnterPressed.apply(null, arguments)
                        },
                        focus: function ($event) {
                          return _vm.setCurrentFieldById(_vm.field.id)
                        },
                      },
                      model: {
                        value: _vm.value,
                        callback: function ($$v) {
                          _vm.value = $$v
                        },
                        expression: "value",
                      },
                    })
                  : _vm.field.meta.type === "date"
                  ? _c("b-form-input", {
                      ref: _vm.field.id,
                      attrs: {
                        state: _vm.validationState(validationContext),
                        type: "date",
                      },
                      on: {
                        input: _vm.valueUpdated,
                        keydown: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          $event.preventDefault()
                          return _vm.handleEnterPressed.apply(null, arguments)
                        },
                        focus: function ($event) {
                          return _vm.setCurrentFieldById(_vm.field.id)
                        },
                      },
                      model: {
                        value: _vm.value,
                        callback: function ($$v) {
                          _vm.value = $$v
                        },
                        expression: "value",
                      },
                    })
                  : _vm.field.meta.type === "ssn"
                  ? _c("b-form-input", {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: "###-##-####",
                          expression: "'###-##-####'",
                        },
                      ],
                      attrs: {
                        state: _vm.validationState(validationContext),
                        placeholder: "Social Security Number",
                        type: "text",
                      },
                      on: {
                        input: _vm.valueUpdated,
                        keydown: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          $event.preventDefault()
                          return _vm.handleEnterPressed.apply(null, arguments)
                        },
                        focus: function ($event) {
                          return _vm.setCurrentFieldById(_vm.field.id)
                        },
                      },
                      model: {
                        value: _vm.value,
                        callback: function ($$v) {
                          _vm.value = $$v
                        },
                        expression: "value",
                      },
                    })
                  : _vm.field.meta.type === "signature"
                  ? _c("b-form-input", {
                      staticClass: "resource-signature",
                      attrs: { state: _vm.validationState(validationContext) },
                      on: {
                        input: _vm.valueUpdated,
                        keydown: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          $event.preventDefault()
                          return _vm.handleEnterPressed.apply(null, arguments)
                        },
                        focus: function ($event) {
                          return _vm.setCurrentFieldById(_vm.field.id)
                        },
                      },
                      model: {
                        value: _vm.value,
                        callback: function ($$v) {
                          _vm.value = $$v
                        },
                        expression: "value",
                      },
                    })
                  : _vm.field.meta.type === "checkbox"
                  ? _c("b-form-checkbox", {
                      staticClass:
                        "resource-checkbox tab-order-schema-checkbox",
                      attrs: { value: "true", "unchecked-value": "false" },
                      on: {
                        input: _vm.valueUpdated,
                        keydown: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          $event.preventDefault()
                          return _vm.handleEnterPressed.apply(null, arguments)
                        },
                        focus: function ($event) {
                          return _vm.setCurrentFieldById(_vm.field.id)
                        },
                      },
                      model: {
                        value: _vm.value,
                        callback: function ($$v) {
                          _vm.value = $$v
                        },
                        expression: "value",
                      },
                    })
                  : _vm.field.meta.type === "multiline_text"
                  ? _c("b-form-textarea", {
                      staticClass: "text-area-input",
                      attrs: {
                        id: "text-area-input",
                        placeholder: _vm.placeholderText,
                        state: _vm.validationState(validationContext),
                        "max-length": _vm.field.meta.character_limit,
                        "no-resize": "",
                      },
                      on: {
                        input: _vm.valueUpdated,
                        keydown: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          $event.preventDefault()
                          return _vm.handleEnterPressed.apply(null, arguments)
                        },
                        focus: function ($event) {
                          return _vm.setCurrentFieldById(_vm.field.id)
                        },
                      },
                      model: {
                        value: _vm.value,
                        callback: function ($$v) {
                          _vm.value = $$v
                        },
                        expression: "value",
                      },
                    })
                  : _c("b-form-input", {
                      attrs: {
                        placeholder: _vm.placeholderText,
                        state: _vm.validationState(validationContext),
                        "max-length": _vm.field.meta.character_limit,
                      },
                      on: {
                        input: _vm.valueUpdated,
                        keydown: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          $event.preventDefault()
                          return _vm.handleEnterPressed.apply(null, arguments)
                        },
                        focus: function ($event) {
                          return _vm.setCurrentFieldById(_vm.field.id)
                        },
                      },
                      model: {
                        value: _vm.value,
                        callback: function ($$v) {
                          _vm.value = $$v
                        },
                        expression: "value",
                      },
                    }),
                _vm.isDisabled
                  ? _c("b-form-text", [
                      _vm._v(
                        "\n      This field can't be changed at this time.\n    "
                      ),
                    ])
                  : _vm._e(),
                _vm.errors(_vm.field.name)
                  ? _c("p", { staticClass: "invalid" }, [
                      _vm._v(
                        "\n      " +
                          _vm._s(_vm.errors(_vm.field.name)) +
                          "\n    "
                      ),
                    ])
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }